
<template>
  <el-divider  content-position="left" border-style="dashed">
    <el-button @click="AddPicture({Sort:0})">新增</el-button>
    </el-divider>

  <el-row>
    <el-table
      highlight-current-row
      :data="ProductPictures"
      border
      style="width: 100%"
      height="500px"
    >
      <el-table-column type="index" width="50" />
      <el-table-column label="图片"  >
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-image
              style="width: 100px; height: 100px"
              :src="scope.row.CompleteImageFormet"
              fit="fill"
            ></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="排序" width="180">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <p class="conceal_txt" style="margin-left: 10px">
              {{ scope.row.Sort }}
            </p>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="500px">
        <template #default="scope">
          <el-button type="button" size="small" @click="AddPicture(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            @click="DeleteProductPictures(scope.$index, scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </el-row>

  <!-- 图片 -->
  <el-dialog  title="编辑图片"  v-model="PictureEditVisible" width="550px" :show-close="false">
    <el-form>
      <el-form-item label="图片">
        <PictureCover
          v-model="PictureOpt.CompleteImage"
          v-model:ImageFormet="PictureOpt.CompleteImageFormet"
            v-model:ImageModel="ImageModel"
        />
      </el-form-item>
      <el-form-item label="排序">
        <el-input-number v-model="PictureOpt.Sort" />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="PictureEditVisible = false">关闭</el-button>
        <el-button @click="SavePicture()">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { ElMessage, ElMessageBox } from "element-plus"
import { Plus } from "@element-plus/icons-vue"
import axios from "../../../commons/AxiosMethod.js"
import PictureCover from "../../../components/PictureCover.vue"
export default {
  name: "header",
  data () {
    return {
      PictureEditVisible: false,
      ProductId: "",
      PictureOpt: {}, // 当前操作图片
      ProductPictures: [],

      ImageModel: {}// 图片返回
    }
  },
  components: {
    PictureCover
  },
  props: {
    Id: ""
  },
  mounted () {
    this.AttributeGroups = this.AttributeGroup
    this.ProductId = this.Id
    this.GetPictureByProductId()
    // 这个是页面创建的时候，就执行相关的方法
  },
  methods: {
    // 新增
    AddPicture (item) {
      this.PictureEditVisible = true
      this.PictureOpt = item
    },
    // 根据商品获取图片集合
    GetPictureByProductId () {
      axios.apiMethod(
        "/api/Products/GetPictureByProductId",
        "get",
        { Id: this.ProductId },
        (result) => {
          if (result.Code == 200) {
            this.ProductPictures = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 删除图片
    DeleteProductPictures (index, item) {
      ElMessageBox.confirm("确定要删除吗?")
        .then(() => {
          var params = { Id: item.Id }
          axios.apiMethod(
            "/api/Products/DeletePicture",
            "get",
            params,
            (result) => {
              if (result.Code == 200) {
                this.GetPictureByProductId()
              } else {
                ElMessageBox({
                  message: result.FullMsg,
                  type: "error"
                })
              }
            }
          )
        })
        .catch(() => {
          // catch error
        })
    },
    // 图片上传成功操作
    SavePicture () {
      // var param = {
      //   ProductId: this.ProductModel.Id,
      //   ThumbnailImage: response.Data.ThumbnailFileName,
      //   CompleteImage: response.Data.FileName,
      // };
      this.PictureOpt.ProductId = this.ProductId
      this.PictureOpt.ThumbnailImage = this.ImageModel.ThumbnailFileName
      axios.apiMethod(
        "/api/Products/AddPicture",
        "post",
        this.PictureOpt,
        (result) => {
          if (result.Code == 200) {
            this.GetPictureByProductId()
            this.PictureEditVisible = false
            this.PictureOpt = {}
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>
<style scoped>
.form-item {
  width: 100% !important;
}
</style>
