<template>
  <div class="conetnt">
    <el-form label-width="180px">
      <el-row>
        <el-col :span="6">
          <el-form-item label="所属分类">
            <el-select
              size="small"
              v-model="ScreenModel.CategoryOptIds"
              multiple
              style="width: 100%"
              placeholder=""
              clearable
              collapse-tags
            >
              <el-option
                v-for="item in CategoryOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="名称">
            <el-input size="small" v-model="ScreenModel.Name" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-button size="small" @click="GetProducts()" type="primary"
            >查询</el-button
          >
        </el-col>
      </el-row>
    </el-form>
    <el-button size="small" @click="AddProduct()">新增</el-button>
    <br />
    <br />
    <el-table
      highlight-current-row
      :data="ProductDatas"
      border
      style="width: 100%"
      :height="clientHeight - 180 + 'px'"
    >
      <el-table-column type="index" width="50" />
      <el-table-column label="名称" width="180">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <p class="conceal_txt" style="margin-left: 10px">
              {{ scope.row.Name }}
            </p>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="类型" width="100">
        <template #default="scope">
          {{ scope.row.ProductTypeFormat }}
        </template>
      </el-table-column>
      <el-table-column label="编号" width="240">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.Code }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="价格" width="180">
        <template #default="scope">
          <div style="display: flex; align-items: center">
            <el-icon><timer /></el-icon>
            <span style="margin-left: 10px">{{ scope.row.Price }}</span>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="SKU" prop="Sku" />
      <el-table-column label="库存" prop="StockCount" />

      <el-table-column label="操作" fixed="right" width="500px">
        <template #default="scope">
          <el-button
            size="small"
            type="primary"
            @click="EditWindow(scope.$index, scope.row)"
            >编辑</el-button
          >
          <el-button
            type="warning"
            size="small"
            @click="EditDetail(scope.$index, scope.row)"
            >详情</el-button
          >
          <el-button
            type="success"
            size="small"
            @click="EditImages(scope.$index, scope.row)"
            >图片</el-button
          >
          <el-button
            size="small"
            type="primary"
            @click="EditLanguages(scope.$index, scope.row)"
            >多语言</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="EditProductAttributeGroup(scope.$index, scope.row)"
            >选择属性</el-button
          >
          <el-button
            size="small"
            type="danger"
            @click="EditProductRemark(scope.$index, scope.row)"
            >编辑要求</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-model:currentPage="currentPage2"
      :page-sizes="[1, 200, 300, 400]"
      :page-size="Size"
      layout="sizes, prev, pager, next"
      :total="ProductTotal"
      @size-change="GetProductSizeChange"
      @current-change="GetProductCurrentChange"
    >
      <!-- // prev-click	用户点击上一页按钮改变当前页时触发	新当前页
    // next-click	用户点击下一页按钮改变当前页时触发	新当前页 -->
    </el-pagination>
  </div>

  <!-- 修改新增商品 -->
  <el-dialog
    v-model="EditOrAddWindow"
    :title="IsAdd ? '新增' : '修改'"
    width="80%"
    :before-close="EditOrAddWindowClose"
  >
    <el-scrollbar height="550px">
      <el-form ref="form" label-position="right" label-width="100px">
        <el-row gutter="30" class="dialog-manages-rowwidth">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="名称">
              <el-input v-model="ProductModel.Name"></el-input>
            </el-form-item>
            <el-form-item label="所属分类">
              <el-select
                v-model="CategoryOpts"
                multiple
                style="width: 100%"
                size="large"
                placeholder=""
              >
                <el-option
                  v-for="item in CategoryOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="推广标签">
              <el-select
                v-model="ProductModel.ProductLabel"
                style="width: 100%"
                size="large"
                placeholder=""
              >
                <el-option
                  v-for="item in ProductLabels"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="细分明细类型">
              <el-select
                v-model="ProductModel.ProductSubdivision"
                style="width: 100%"
                size="large"
                placeholder=""
              >
                <el-option
                  v-for="item in ProductSubdivisionList"
                  :key="item.Key"
                  :label="item.Value"
                  :value="item.Key"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="Sku">
              <el-input v-model="ProductModel.Sku"></el-input>
            </el-form-item>
            <el-form-item label="编号">
              <el-input v-model="ProductModel.Code" disabled></el-input>
            </el-form-item>
            <el-form-item label="备用名称">
              <el-input v-model="ProductModel.ReserveName"></el-input>
            </el-form-item>

            <el-form-item label="价格">
              <el-input-number v-model="ProductModel.Price" :precision="2" />
            </el-form-item>

            <el-form-item label="排序">
              <el-input-number v-model="ProductModel.Sort" />
            </el-form-item>

            <el-form-item label="原价">
              <el-input-number v-model="ProductModel.OldPrice" :precision="2" />
            </el-form-item>
            <el-form-item label="是否含税">
              <el-switch
                v-model="ProductModel.IncludeTax"
                size="small"
                active-text="含税"
                inactive-text="不含税"
              />
            </el-form-item>

            <el-form-item label="税率">
              <el-input-number v-model="ProductModel.TaxRate" :precision="2" />
            </el-form-item>

            <el-form-item label="价格描述">
              <el-input v-model="ProductModel.PriceDescription"></el-input>
            </el-form-item>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <el-form-item label="最大购买数量">
              <el-input-number
                v-model="ProductModel.MaxCount"
                :min="1"
                :max="9999"
              />
            </el-form-item>
            <el-form-item label="库存">
              <el-input-number
                v-model="ProductModel.StockCount"
                :min="1"
                :max="9999"
              />
            </el-form-item>
            <el-form-item label="介绍">
              <el-input
                v-model="ProductModel.Description"
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="封面图片">
              <PictureCover
                v-model="ProductModel.Images"
                v-model:ImageFormet="ProductModel.CompleteImages"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DeleteProduct(0, ProductModel)" type="danger"
          >删除</el-button
        >
        <el-button @click="EditOrAddWindowClose">关闭</el-button>
        <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 详情编辑 -->
  <el-dialog
    v-model="DetailVisibleWindow"
    :fullscreen="true"
    :show-close="false"
  >
    <div class="editorContent">
      <WangEditor ref="editorModel"> </WangEditor>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="DetailWindowClose">关闭</el-button>
        <el-button type="primary" @click="DetailEditOrAddSave()"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <!-- 图片 -->
  <el-dialog
    title="图片管理"
    v-model="PictureVisibleWindow"
    width="60%"
    :show-close="false"
  >
    <ProductPicture :Id="ProductModel.Id" />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="PictureVisibleWindow = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 查看大图 -->
  <el-dialog
    v-model="LookProductPictureVisible"
    width="50%"
    :before-close="handleClose"
  >
    <div class="lookProductPictureContent">
      <div class="ThumbnailImage">
        <el-image
          style="width: 100%"
          :src="ProductPictureModel.ThumbnailImage"
          :fit="fit"
        ></el-image>
      </div>
      <div class="CompleteImage">
        <el-image
          style="width: 100%"
          :src="ProductPictureModel.CompleteImage"
          :fit="fit"
        ></el-image>
      </div>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="LookProductPictureVisible = false">关闭</el-button>
      </span>
    </template>
  </el-dialog>

  <!-- 多语言 -->
  <el-dialog
    v-model="LanguagesVisible"
    width="80%"
    :before-close="LanguagesClose"
  >
    <el-tabs v-model="activeName" type="card" class="demo-tabs">
      <el-tab-pane
        v-for="(item, index) in this.ProductModel.Languages"
        :key="index"
        :label="item.LanguageName"
        :name="item.Name"
      >
        <el-form ref="form" label-position="right" label-width="100px">
          <el-row gutter="30" class="dialog-manages-rowwidth">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <el-form-item label="名称">
                <el-input v-model="item.Language.Name"></el-input>
              </el-form-item>
              <el-form-item label="备用名称">
                <el-input v-model="item.Language.ReserveName"></el-input>
              </el-form-item>
              <el-form-item label="价格描述">
                <el-input v-model="item.Language.PriceDescription"></el-input>
              </el-form-item>
              <el-form-item label="描述信息">
                <el-input v-model="item.Language.Description"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="LanguagesVisible = false">关闭</el-button>
        <el-button type="primary" @click="EditOrAddSave()">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script  >
import axios from "../../../commons/AxiosMethod.js"
import { ElMessageBox, ElSelect } from "element-plus"
import {
  Plus,
  ZoomIn,
  Download,
  Delete,
  UploadFilled
} from "@element-plus/icons-vue" 
import WangEditor from "../../../components/WangEditor.vue"
import PictureCover from "../../../components/PictureCover.vue"
import ProductPicture from "./ProductPicture.vue"
import commons from "../../../commons/commons.js"
export default {
  data () {
    return {
      IsAdd: false,
      EditOrAddWindow: false,
      ProductDatas: [],
      ProductModel: {
        Price: 0,
        OldPrice: 0,
        Sort: 0,
        MaxCount: 0,
        StockCount: 0
      }, // 当前操作商品

      ScreenModel: {
        CategoryOptIds: [] // 筛选条件
      }, // 筛选模型

      imageUrl: null,
      fileList: [],
      UploadImageUrl: "",
      headersImage: "",
      dialogImageUrl: "",
      dialogVisible: false,
      CategoryOptions: [], // 分类选择列表
      CategoryOpts: [], // 选中分类
      ClassifyDatas: [],

      ProductTotal: 50,
      Size: 50, // 页大小
      Page: 1, // 页码

      DetailVisibleWindow: false, // 详情
      ProductDetail: {}, // 详情内容

      ProductPictures: [], // 图片集合
      PictureVisibleWindow: false,
      UploadPictureImageUrl: "",
      ProductPictureModel: {}, // 当前操作图像
      LookProductPictureVisible: false,

      // 推广标签
      ProductLabels: [], // 标签类型
      ProductLabelOpt: "", // 选中标签

      editor: null, // 富文本编辑器

      clientWidth: document.body.clientWidth,
      clientHeight: document.documentElement.clientHeight,

      LanguagesVisible: false, // 富文本编辑器
      UploadImages: "", // 上传地址

      ProductSubdivisionList: [], // 细分明细类型
      ProductSubdivisionOpt: {} // 细分明细

    }
  },
  name: "App",
  components: {
    Plus,
    ZoomIn,
    Download,
    Delete,
    WangEditor,
    UploadFilled,
    PictureCover,
    ProductPicture
  },
  mounted () {
    this.GetProducts()
    this.GetCategory()
    this.GetProductLabels()
    this.GetProductSubdivisionEnum()

    this.UploadImageUrl = axios.GetUrl() + "/api/Upload/ImageFile"
    this.UploadPictureImageUrl =
      axios.GetUrl() + "/api/Upload/UploadPictureSuccess"
    this.headersImage = {
      Authorization: localStorage.getItem("Authentication_Header")
    }
  },
  methods: {
    // 编辑属性组
    EditProductAttributeGroup (index, item) {
      this.$router.push({
        name: "ProductAttributeGroupRelevance",
        query: {
          Id: item.Id
        }
      })
    },
    EditProductRemark (index, item) {
      this.$router.push({
        name: "requireManage",
        query: {
          Id: item.Id
        }
      })
    },
    // 关闭语言框
    LanguagesClose () {
      this.LanguagesVisible = false
    },
    // 编辑语言
    EditLanguages (index, item) {
      this.ProductModel = item
      this.LanguagesVisible = true
    },
    // 查看大图
    LookProductPictures (index, item) {
      this.LookProductPictureVisible = true
      this.ProductPictureModel = item
    },

    // 图片上传成功操作
    UploadPictureSuccess (response, file, fileList) {
      if (response.Code == 200) {
        var param = {
          ProductId: this.ProductModel.Id,
          ThumbnailImage: response.Data.ThumbnailFileName,
          CompleteImage: response.Data.FileName
        }

        axios.apiMethod("/api/Products/AddPicture", "post", param, (result) => {
          if (result.Code == 200) {
            this.GetPictureByProductId()
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        })
      }
    },

    // 编辑图片
    EditImages (index, item) {
      this.ProductModel = item
      this.PictureVisibleWindow = true
      this.GetPictureByProductId()
    },
    // 根据商品获取图片集合
    GetPictureByProductId () {
      axios.apiMethod(
        "/api/Products/GetPictureByProductId",
        "get",
        { Id: this.ProductModel.Id },
        (result) => {
          if (result.Code == 200) {
            this.ProductPictures = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 编辑详细
    EditDetail (index, item) {
      this.DetailVisibleWindow = true

      axios.apiMethod(
        "/api/Products/GetPrdouctDetail",
        "get",
        { Id: item.Id },
        (result) => {
          if (result.Code == 200) {
            this.ProductDetail = result.Data
            const editorModel = this.$refs.editorModel
            editorModel.editor.txt.html("")
            editorModel.editor.txt.html(result.Data.Content)
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 保存详情信息
    DetailEditOrAddSave () {
      const editorModel = this.$refs.editorModel
      var content = editorModel.editor.txt.html()
      this.ProductDetail.Content = content

      axios.apiMethod(
        "/api/Products/UpdateOrAddDetail",
        "post",
        this.ProductDetail,
        (result) => {
          if (result.Code == 200) {
            ElMessageBox({
              title: "提示",
              message: "保存成功",
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 详情页关闭
    DetailWindowClose () {
      this.DetailVisibleWindow = false
    },
    // 获取分类
    GetCategory () {
      var param = { Size: 2000 }
      axios.apiMethod(
        "/api/Category/GetCategoryAll",
        "post",
        param,
        (result) => {
          if (result.Code == 200) {
            this.ClassifyDatas = result.Data
            this.ClassifyDatas.forEach((e) => {
              this.CategoryOptions.push({ label: e.Name, value: e.Id })
            })

            // :label="item.label"
            //           :value="item.value"
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },

    // 上传文件前操作
    UploadImage (file) {
      const ispng = file.type === "image/png"
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!ispng) {
        ElMessage.error("请上传png格式文件")
        return false
      }

      if (!isLt2M) {
        ElMessage.error("请上传2M以内文件")
        return false
      }
    },

    EditOrAddWindowClose () {
      this.EditOrAddWindow = false
    },
    // 打开编辑页面
    EditWindow (index, item) {
      console.log(item)
      this.EditOrAddWindow = true
      this.ProductModel = item

      this.CategoryOpts = []

      item.Categorys.forEach((e) => {
        this.CategoryOpts.push(e.Id)
      })

      this.fileList = []
      if (this.ProductModel.Images != "" && this.ProductModel.Images != null) {
        const img = this.ProductModel.CompleteImages
        this.fileList.push({ url: img })
      }
    },
    // 删除商品
    DeleteProduct (index, item) {
      commons.confirmDelete(item.Name, () => {
        axios.apiMethod(
          "/api/Products/DeleteProduct?Id=" + item.Id,
          "get",
          {},
          (result) => {
            if (result.Code == 200) {
              this.EditOrAddWindow = false
              this.GetProducts()
            } else {
              ElMessageBox({
                message: result.FullMsg,
                type: "error"
              })
            }
          }
        )
      })
      // ElMessageBox.confirm("确定要删除" + item.Name + "吗?")
      //   .then()
      //   .catch(() => {
      //     // catch error
      //   });
    },
    // 新增
    AddProduct () {
      this.ProductModel = {
        Price: 0,
        MaxCount: 9999,
        TaxRate: 100,
        IncludeTax: false,
        OldPrice: 0,
        StockCount: 0,
        Sort: 0
      }
      this.EditOrAddWindow = true
      this.IsAdd = true
    },
    // pageSize 改变时触发
    GetProductSizeChange (pageSize) {
      this.Size = pageSize
      this.GetProducts()
    },
    // current-change 改变时触发
    GetProductCurrentChange (pageSize) {
      this.Page = pageSize
      this.GetProducts()
    },

    GetProducts () {
      var param = {
        Page: this.Page,
        Size: this.Size,
        Where: this.ScreenModel // { CategoryOptIds: this.SelectCategoryOpts },
      }
      axios.apiMethod("/api/Products/GetProducts", "post", param, (result) => {
        if (result.Code == 200) {
          this.ProductDatas = result.Data.Datas
          this.ProductTotal = result.Data.Count
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    },
    // 保存或者修改
    EditOrAddSave () {
      this.ProductModel.Categorys = []
      this.CategoryOpts.forEach((e) => {
        this.ProductModel.Categorys.push({ Id: e })
      })

      axios.apiMethod(
        "/api/Products/AddOrEditProducts",
        "post",
        this.ProductModel,
        (result) => {
          if (result.Code == 200) {
            this.GetProducts()
            this.EditOrAddWindowClose()
            ElMessageBox({
              title: "提示",
              message: "保存成功",
              type: "success"
            })
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    },
    // 获取推广标签类型
    GetProductLabels () {
      axios.apiMethod("/api/Products/GetProductLabels", "get", {}, (result) => {
        if (result.Code == 200) {
          this.ProductLabels = result.Data
        } else {
          ElMessageBox({
            message: result.FullMsg,
            type: "error"
          })
        }
      })
    },
    // 获取细分明细
    GetProductSubdivisionEnum () {
      axios.apiMethod(
        "/sys/SystemData/GetProductSubdivisionEnum",
        "get",
        {},
        (result) => {
          if (result.Code == 200) {
            this.ProductSubdivisionList = result.Data
          } else {
            ElMessageBox({
              message: result.FullMsg,
              type: "error"
            })
          }
        }
      )
    }
  }
}
</script>

<style lang="less" scoped>
.conetnt {
  padding: 10px;
}

.lookProductPictureContent {
  display: flex;
  justify-content: space-around;
  width: 100%;
  .ThumbnailImage {
    width: 300px;
  }

  .CompleteImage {
    width: calc(100% - 400px);
  }
}

.editorContent {
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

.manage_title {
  height: 50px;
  background-color: rgb(67, 74, 80);
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.dialog-manages-height {
  height: 400px;
  overflow: auto;
  width: 95%;
  padding: 10px;
}

.dialog-manages-rowwidth {
  width: 95% !important;
}

.conceal_txt {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
